import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; //

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation(); 

  if (!currentUser) {
    // Збереження поточного шляху перед редиректом на сторінку входу
    sessionStorage.setItem('redirectPath', location.pathname + location.search);
    // Якщо користувач не аутентифікований, редирект на сторінку входу
    return <Navigate to="/login" />;
  }

  // Якщо користувач аутентифікований, відображайте переданий в захищений маршрут компонент
  return children;
};

export default ProtectedRoute;