import React, { useState } from 'react';
import styles from './TimerPopup.module.css'; // Змініть шлях відповідно до вашої структури
import { startTimerInFirebase } from '../services/firebaseService'; // Змініть шлях відповідно до вашої структури

const TimerPopup = ({ onStart, onCancel }) => {
    const [minutes, setMinutes] = useState(5);

    const handleStart = () => {
        const seconds = minutes * 60;
        startTimerInFirebase(seconds); // Оновлено для запису в Firebase
        onStart(seconds);
    };

    return (
        <div className={styles.timerPopup}>
            <div className={styles.timerContent}>
                <p>Minutes</p>
                <input 
                    type="number" 
                    value={minutes} 
                    onChange={(e) => setMinutes(e.target.value)} 
                />
                <div className={styles.timerPopupButtons}>
                    <button onClick={handleStart}>Start</button>
                    <button onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default TimerPopup;