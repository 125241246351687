import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../firebase-config';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { ref, get, set } from "firebase/database";
import styles from './LoginPage.module.css';

const provider = new GoogleAuthProvider();

const LoginPage = () => {
  const navigate = useNavigate();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        
        // Перевірка, чи є email користувача в колекції allowedEmails або чи належить він до домену amo.tech
        const emailCheckRef = ref(database, `allowedEmails/${user.email.replace(/\./g, ',')}`); // замінюємо крапки на коми для сумісності з Firebase ключами
        get(emailCheckRef).then((emailSnapshot) => {
          if (emailSnapshot.exists() || user.email.endsWith('@amo.tech')) {
            // Логіка створення профілю користувача, якщо він є в allowedEmails або має домен amo.tech
            const userRef = ref(database, 'users/' + user.uid);
            get(userRef).then((snapshot) => {
              if (!snapshot.exists()) {
                set(userRef, {
                  displayName: user.displayName,
                  email: user.email,
                  photoURL: user.photoURL,
                }).then(() => {
                  console.log("User profile created successfully.");
                }).catch((error) => {
                  console.error("Error creating user profile:", error);
                });
              } else {
                console.log("User profile already exists.");
              }
            });

            const redirectPath = sessionStorage.getItem('redirectPath') || '/dashboard';
            navigate(redirectPath, { replace: true });
          } else {
            // Якщо користувач не в списку дозволених і не має email з домену amo.tech
            signOut(auth).then(() => {
              alert("Access denied. Your email is not allowed.");
              navigate('/login', { replace: true });
            });
          }
        }).catch((error) => {
          console.error("Error checking allowed emails:", error);
        });
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error);
      });
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <h1 className={styles.amoRetroTitle}>RetroPaw</h1>
        <button onClick={signInWithGoogle} className={styles.googleSignInButton}>
          Sign in with Google
        </button>
      </div>
    </div>
  );
};

export default LoginPage;