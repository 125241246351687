import React from 'react';
import styles from './HomePage.module.css'; // Припускаємо, що стилі вже визначені у відповідному CSS модулі
import { useNavigate } from 'react-router-dom';



const HomePage = () => {
  const navigate = useNavigate();
  const handleGetStartedClick = () => {
    navigate('/dashboard');
  };
  return (
    <div className={styles.homePage}>

      <header className={styles.header}>
        <div>
          <h1>Enhance Your Team's Performance with Engaging Sprint Retrospectives</h1>
          <p>Join forces with your remote team and elevate your effectiveness with RetroPaw – a straightforward, user-friendly, and visually appealing platform designed for growth.</p>
          <button onClick={handleGetStartedClick} className={styles.getStartedButton}>Get Started for Free</button>
        </div>
        <img src="/homepage.png" alt="Descriptive Alt Text" className={styles.headerImage} />
      </header>
      <section className={styles.mainContent}>
        {/* Тут може бути додатковий контент або компоненти */}
      </section>
    </div>
  );
};

export default HomePage;