import React, { useState, useEffect } from 'react';
import { getAuth, updateProfile, signOut } from 'firebase/auth'; // Додайте signOut
import { getDatabase, ref, update } from "firebase/database";
import { useNavigate } from 'react-router-dom'; // Для редиректу після виходу
import styles from './ProfilePage.module.css'; 

const ProfilePage = () => {
  const auth = getAuth();
  const db = getDatabase();
  const navigate = useNavigate(); // Хук для навігації
  const user = auth.currentUser;

  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [photoURL, setPhotoURL] = useState(user?.photoURL || '');
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (user) {
      setDisplayName(user.displayName || '');
      setPhotoURL(user.photoURL || '');
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await signOut(auth); // Виконуємо вихід
      navigate('/login'); // Редирект на сторінку логіна
    } catch (error) {
      console.error('Error signing out: ', error);
      alert('Error signing out, please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);

    try {
      console.log('Updating profile for user:', user.uid); // Додайте логування
      await updateProfile(user, {
        displayName,
        photoURL,
      });

      const userRef = ref(db, `users/${user.uid}`);
      await update(userRef, {
        displayName,
        photoURL,
      });

      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile: ', error);
      alert('Error updating profile, please try again.');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className={styles.profilePage}>
      <h1>User Profile</h1>
      <form onSubmit={handleSubmit} className={styles.profileForm}>
      <div className={styles.formGroup}>
          <label htmlFor="displayName">Name:</label>
          <input
            id="displayName"
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="photoURL">Photo URL:</label>
          <input
            id="photoURL"
            type="text"
            value={photoURL}
            onChange={(e) => setPhotoURL(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Email:</label>
          <span>{user?.email}</span>
        </div>
        <button type="submit" className={styles.updateButton} disabled={updating}>
          {updating ? 'Updating...' : 'Update Profile'}
        </button>
        <button onClick={handleLogout} type="button" className={styles.logoutButton}>
          Logout
        </button>
      </form>
    </div>
  );
};

export default ProfilePage;