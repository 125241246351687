import React, { useState, useEffect } from 'react'; // Видалити дублювання імпорту useEffect
import styles from './TimerDisplay.module.css';
import { listenForTimerChanges, cancelTimerInFirebase } from '../services/firebaseService'; // Змініть шлях відповідно до вашої структури

const TimerDisplay = ({ seconds, onCancel }) => {
    const [timeLeft, setTimeLeft] = useState(seconds);
    const [isTimeOver, setIsTimeOver] = useState(false);

    useEffect(() => {
        const unsubscribe = listenForTimerChanges((timerData) => {
            if (timerData) {
                const currentTime = Date.now();
                const timePassed = Math.floor((currentTime - timerData.startTime) / 1000);
                const timeLeft = timerData.duration - timePassed;
                setTimeLeft(timeLeft > 0 ? timeLeft : 0);
            } else {
                setTimeLeft(0); // Якщо таймер видалено з бази
            }
        });

        return () => unsubscribe(); // Вимкнути слухач при демонтажі компоненту
    }, []);

    useEffect(() => {
        let interval = null;

        if (!isTimeOver && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeLeft <= 0) {
            setIsTimeOver(true);
        }

        return () => clearInterval(interval);
    }, [timeLeft, isTimeOver]);

    const handleCancel = () => { // Перемістити всередину компонента
        cancelTimerInFirebase(); // Видаляємо таймер з Firebase
        onCancel();
    };

    const minutes = Math.floor(timeLeft / 60);
    const secs = timeLeft % 60;

    if (isTimeOver || isNaN(timeLeft)) {
        return (
            <div className={styles.timerDisplay} onClick={handleCancel}> {/* Використовувати handleCancel замість onCancel */}
                <span className={styles.pauseIcon}>&#10073;&#10073;</span>
                <span>Time is over</span>
            </div>
        );
    }

    return (
        <div className={styles.timerDisplay} onClick={handleCancel}> {/* Використовувати handleCancel замість onCancel */}
            <span className={styles.pauseIcon}>&#10073;&#10073;</span>
            <span>{minutes < 10 ? `0${minutes}` : minutes}:{secs < 10 ? `0${secs}` : secs}</span>
        </div>
    );
};

export default TimerDisplay;
