import React, { useState } from 'react';
import styles from './ShareButton.module.css'; // Шлях до вашого CSS файлу

const ShareButton = ({ boardId }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleShare = () => {
    const boardUrl = `${window.location.origin}/board/${boardId}`;
    navigator.clipboard.writeText(boardUrl).then(() => {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 1000); // Показуємо попап на 1 секунду
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <>
      <button className={styles.share} onClick={handleShare}>Share</button>
      {showPopup && <div className={styles.popup}>Board URL copied!</div>}
    </>
  );
};

export default ShareButton;
