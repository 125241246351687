import React, { useState, useEffect } from 'react';
import { getBoards, addNewBoard, archiveBoard, renameBoard } from '../services/firebaseService';
import styles from './DashboardPage.module.css';
import { Link } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import ShareButton from '../components/ShareButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const DashboardPage = () => {
    const [boards, setBoards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeBoardMenu, setActiveBoardMenu] = useState(null);

    useEffect(() => {
        const fetchBoards = async () => {
            setLoading(true);
            try {
                const fetchedBoards = await getBoards();
                // Сортування бордів за датою в порядку спадання
                const sortedBoards = fetchedBoards.sort((a, b) => new Date(b.date) - new Date(a.date));
                setBoards(sortedBoards);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
    
        fetchBoards();
    }, []);

    const handleAddBoard = async () => {
        const newBoardName = prompt('Enter the name of the new board:');
        if (newBoardName) {
          try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
              const newBoardId = await addNewBoard(user.uid, newBoardName);
              const newBoardData = {
                id: newBoardId,
                name: newBoardName,
                date: new Date().toISOString(),
                ownerId: user.uid,
                columns: {
                  'column1': { name: 'Went Well', cards: {} },
                  'column2': { name: 'To Improve', cards: {} },
                  'column3': { name: 'Action Items', cards: {} }
                }
              };
              // Додавання нової борди на початок масиву
              setBoards([newBoardData, ...boards]);
            } else {
              throw new Error("User is not authenticated");
            }
          } catch (error) {
            console.error('Error creating new board:', error);
          }
        }
    };

      const handleArchiveBoard = async (boardId) => {
        try {
          await archiveBoard(boardId);
          // Оновлення стану дошок після архівування
          setBoards(boards.filter((board) => board.id !== boardId));
        } catch (error) {
          console.error('Error archiving board:', error);
          // Тут можна додати обробку помилки
        }
      };

      const handleRenameBoard = async (boardId) => {
        const newName = prompt("Enter the new name for the board:");
        if (newName && newName.trim() !== "") {
          try {
            await renameBoard(boardId, newName);
            const updatedBoards = await getBoards();
            setBoards(updatedBoards);
          } catch (error) {
            console.error('Error renaming board:', error);
            // Тут можна додати обробку помилки
          }
        }
      };

      const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      };

      const getTotalCardsCount = (board) => {
        return Object.values(board.columns || {}).reduce((total, column) => {
          return total + Object.keys(column.cards || {}).length;
        }, 0);
      };

      const handleToggleBoardMenu = (boardId) => {
        const newActiveBoardMenu = activeBoardMenu === boardId ? null : boardId;
        setActiveBoardMenu(newActiveBoardMenu);
    };

      useEffect(() => {
        const closeMenu = (e) => {
            // Перевіряємо, чи клік відбувся поза елементом меню
            if (activeBoardMenu && !e.target.closest(`.${styles.boardMenu}`)) {
                setActiveBoardMenu(null);
            }
        };
        document.addEventListener('click', closeMenu);
        return () => document.removeEventListener('click', closeMenu);
       }, [activeBoardMenu]);

    return (
        <div className={styles.dashboard}>
            <div className={styles.header}>
                <h1>Dashboard</h1>
                <span>{boards.length} Boards</span>
            </div>
            <div className={styles.boardSection}>
                <button onClick={handleAddBoard} className={styles.addButton}>Add board</button>
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>Error: {error.message}</div>
                ) : (
                    boards.map((board) => (
                        <div key={board.id}>
                                <Link to={`/board/${board.id}`} key={board.id} className={styles.boardLink}>
                                    <div className={styles.board}>
                                        <div className={styles.name}>{board.name}</div>
                                        <div className={styles.dateCount}>
                                          <div className={styles.date}>{formatDate(board.date)}</div>
                                          <div className={styles.cardCount}>{getTotalCardsCount(board)} cards</div>
                                        </div>
                                    </div>
                                </Link>
                                <div className={styles.divider}></div>
                                <div className={styles.boardFooter}>
                                  <ShareButton className={styles.boardShare} boardId={board.id} />
                                  <div className={styles.boardMenu} onClick={(e) => handleToggleBoardMenu(board.id, e)}>
                                      <FontAwesomeIcon icon={faEllipsisV} />
                                      <div className={activeBoardMenu === board.id ? styles.boardActions : styles.hidden}>
                                          <button onClick={() => handleRenameBoard(board.id)}>Rename board</button>
                                          <button onClick={() => handleArchiveBoard(board.id)}>Archive Board</button>
                                      </div>
                                  </div>
                                </div>
                        </div>
                    ))
                )}  
            </div>
        </div>
    );
};

export default DashboardPage;