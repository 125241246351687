import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  addCardToColumn,
  editCard,
  deleteCard,
  listenBoardChanges,
  setTimer,
  onTimerChange,
  removeTimer,
} from "../services/firebaseService";
import styles from "./BoardPage.module.css";
import { getAuth } from "firebase/auth";
import TimerPopup from "../components/TimerPopup";
import TimerDisplay from "../components/TimerDisplay";
import ShareButton from "../components/ShareButton";
import { getDatabase, ref, onValue } from "firebase/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Picker from "emoji-picker-react";
import VoteButton from "../components/VoteButton";
import CardDraw from "../components/CardDraw"; // Імпортуємо компонент CardDraw

const BoardPage = () => {
  const { boardId } = useParams();
  const [board, setBoard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newCardText, setNewCardText] = useState("");
  const [addingCard, setAddingCard] = useState(null);
  const [editingCard, setEditingCard] = useState(null);
  const [editCardText, setEditCardText] = useState("");
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [showTimerPopup, setShowTimerPopup] = useState(false);
  const [timerData, setTimerData] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(currentUser?.uid);
  const [users, setUsers] = useState([]);
  const db = getDatabase();
  const [activeCardMenu, setActiveCardMenu] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showEditingEmojiPicker, setShowEditingEmojiPicker] = useState(false);
  // eslint-disable-next-line
  const [emojiPickerPosition, setEmojiPickerPosition] = useState({
    top: 0,
    left: 0,
  });
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);

  // Додайте стан для відображення гри
  const [isGameEnabled, setIsGameEnabled] = useState(false);

  const toggleFilter = () => {
    setIsFilterEnabled(!isFilterEnabled);
  };

  useEffect(() => {
    const unsubscribe = onTimerChange(boardId, (data) => {
      if (data) {
        const currentTime = Date.now();
        const timePassed = Math.floor((currentTime - data.startTime) / 1000);
        const secondsLeft = data.seconds - timePassed;
        setTimerData({ ...data, secondsLeft });
      } else {
        setTimerData(null);
      }
    });

    return () => unsubscribe();
  }, [boardId]);

  const startTimer = (seconds) => {
    setTimer(boardId, seconds);
    setShowTimerPopup(false);
  };

  const cancelTimer = () => {
    removeTimer(boardId);
  };

  useEffect(() => {
    const usersRef = ref(db, "users/");
    const unsubscribe = onValue(usersRef, (snapshot) => {
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const currentUserID = auth.currentUser.uid;
        const sortedUsersArray = [
          { id: currentUserID, ...usersData[currentUserID] },
          ...Object.keys(usersData)
            .filter((key) => key !== currentUserID)
            .map((key) => {
              const { email, ...userDataWithoutEmail } = usersData[key];
              return { id: key, ...userDataWithoutEmail };
            }),
        ];
        setUsers(sortedUsersArray);
      }
    });

    return () => unsubscribe();
  }, [db, auth.currentUser]);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = listenBoardChanges(
      boardId,
      (newBoardData) => {
        setBoard(newBoardData);
        setLoading(false);
        setError(null);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [boardId]);

  const handleAddCard = (columnId) => {
    setAddingCard(columnId);
  };

  const handleToggleEmojiPicker = (event, isEditing) => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

    setEmojiPickerPosition({
      top: buttonRect.bottom + scrollTop,
      left: buttonRect.left + scrollLeft,
    });

    if (isEditing) {
      setShowEditingEmojiPicker(!showEditingEmojiPicker);
    } else {
      setShowEmojiPicker(!showEmojiPicker);
    }
  };

  const onEmojiClick = (emojiObject, event, isEditing) => {
    event.preventDefault();

    const insertAtCursor = (currentText, cursorPosition, textToInsert) => {
      return (
        currentText.substring(0, cursorPosition) +
        textToInsert +
        currentText.substring(cursorPosition, currentText.length)
      );
    };

    if (isEditing) {
      const textarea = document.querySelector(".editingCardTextarea");
      if (textarea) {
        const cursorPosition = textarea.selectionStart;
        const updatedText = insertAtCursor(
          editCardText,
          cursorPosition,
          emojiObject.emoji
        );
        setEditCardText(updatedText);
        setShowEditingEmojiPicker(false);
        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd =
            cursorPosition + emojiObject.emoji.length;
        }, 0);
      }
    } else {
      const textarea = document.querySelector(".newCardTextarea");
      if (textarea) {
        const cursorPosition = textarea.selectionStart;
        const updatedText = insertAtCursor(
          newCardText,
          cursorPosition,
          emojiObject.emoji
        );
        setNewCardText(updatedText);
        setShowEmojiPicker(false);
        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd =
            cursorPosition + emojiObject.emoji.length;
        }, 0);
      }
    }
  };

  const handleSaveNewCard = async (columnId) => {
    if (newCardText.trim() === "") {
      alert("Please enter some text for the card");
      return;
    }
    try {
      const newCardData = {
        text: newCardText,
        author: "Anonymous",
        votes: 0,
      };
      await addCardToColumn(boardId, columnId, newCardData);
      setNewCardText("");
      setShowEmojiPicker(false);
      setAddingCard(null);
    } catch (err) {
      setError(err);
    }
  };

  const handleCancelNewCard = () => {
    setAddingCard(null);
    setNewCardText("");
  };

  useEffect(() => {
    const textarea = document.querySelector(".editingCardTextarea");
    if (textarea) {
      autoGrow(textarea);
    }
  }, [editCardText]);

  const handleEditCard = (columnId, card) => {
    setEditingCard({ columnId, cardId: card.id });
    setEditCardText(card.text);

    setTimeout(() => {
      const textarea = document.querySelector(".editingCardTextarea");
      if (textarea) {
        autoGrow(textarea);
      }
    }, 0);
  };

  const handleSaveEditCard = async () => {
    if (editCardText.trim() === "") {
      alert("Please enter some text for the card");
      return;
    }
    try {
      const updatedCardData = {
        text: editCardText,
      };
      await editCard(
        boardId,
        editingCard.columnId,
        editingCard.cardId,
        updatedCardData
      );
      setEditingCard(null);
      setEditCardText("");
    } catch (err) {
      setError(err);
    }
  };

  const handleCancelEditCard = () => {
    setEditingCard(null);
    setEditCardText("");
  };

  const handleDeleteCard = async (columnId, cardId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this card?"
    );
    if (confirmDelete) {
      try {
        await deleteCard(boardId, columnId, cardId);
      } catch (err) {
        setError(err);
      }
    }
  };

  const handleToggleCardMenu = (cardId) => {
    const newActiveCardMenu = activeCardMenu === cardId ? null : cardId;
    setActiveCardMenu(newActiveCardMenu);
  };

  useEffect(() => {
    const closeMenu = (e) => {
      if (activeCardMenu && !e.target.closest(`.${styles.cardMenu}`)) {
        setActiveCardMenu(null);
      }
    };
    document.addEventListener("click", closeMenu);
    return () => document.removeEventListener("click", closeMenu);
  }, [activeCardMenu]);

  const handleSelectUser = (userId) => {
    setSelectedUserId(userId);
  };

  function autoGrow(element) {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + "px";
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!board) {
    return <div>No board data available.</div>;
  }

  return (
    <div className={styles.boardPage}>
      <header className={styles.header}>
        <h1>{board.name}</h1>
        <div>
          {users.map((user) => (
            <img
              key={user.id}
              src={user.photoURL}
              alt={user.displayName}
              title={user.displayName}
              onClick={() => handleSelectUser(user.id)}
              className={
                selectedUserId === user.id
                  ? styles.selectedAvatar
                  : styles.unselectedAvatar
              }
            />
          ))}
        </div>
        <div className={styles.headerButtons}>
          {showTimerPopup && (
            <TimerPopup
              onStart={startTimer}
              onCancel={() => setShowTimerPopup(false)}
            />
          )}
          <div
            className={
              styles.timerContainer +
              (timerData && timerData.secondsLeft > 0
                ? ` ${styles.active}`
                : "")
            }
          >
            {timerData && timerData.secondsLeft > 0 && (
              <TimerDisplay
                seconds={timerData.secondsLeft}
                onCancel={cancelTimer}
              />
            )}
          </div>
          <button onClick={() => setShowTimerPopup(true)}>New Timer</button>
          <button
            onClick={() => setIsGameEnabled(!isGameEnabled)}
            className={styles.gameButton}
          >
            {isGameEnabled ? "Disable Game" : "Enable Game"}
          </button>
          <button onClick={toggleFilter} className={styles.filterToggleButton}>
            {isFilterEnabled ? "Disable Filter" : "Enable Filter"}
          </button>
          <ShareButton boardId={board.id} />
        </div>
      </header>
      <div className={styles.divider}></div>

      {/* Додаємо компонент CardDraw, якщо гра увімкнена */}
      {isGameEnabled && (
        <div className={styles.cardDrawContainer}>
          <CardDraw users={users} />
        </div>
      )}

      <div className={styles.columns}>
        {board.columns.map((column) => (
          <div key={column.id} className={styles.column}>
            <h2>{column.name}</h2>
            <button
              onClick={() => handleAddCard(column.id)}
              className={styles.addCardButton}
            ></button>
            {addingCard === column.id && (
              <div
                className={`${styles.newCardInput} ${styles.emojiPickerContainer}`}
              >
                <textarea
                  className="newCardTextarea"
                  value={newCardText}
                  onChange={(e) => setNewCardText(e.target.value)}
                  placeholder="Enter card text"
                  onInput={(e) => autoGrow(e.target)}
                />
                <button
                  onClick={(event) => handleToggleEmojiPicker(event, false)}
                  className={styles.emojiPickerToggle}
                >
                  😊
                </button>
                {showEmojiPicker && (
                  <div className={styles.emojiPickerPopup}>
                    <Picker
                      onEmojiClick={(emojiObject, event) =>
                        onEmojiClick(emojiObject, event, false)
                      }
                    />
                  </div>
                )}
                <div className={styles.buttonContainer}>
                  <button
                    className={styles.newCardInputButton}
                    onClick={() => handleSaveNewCard(column.id)}
                  >
                    Save
                  </button>
                  <button
                    className={styles.newCardInputButton}
                    onClick={handleCancelNewCard}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {column.cards
              .filter(
                (card) => !isFilterEnabled || card.ownerId === selectedUserId
              )
              .map((card) => (
                <div
                  key={card.id}
                  className={`${styles.card} ${
                    selectedUserId === card.ownerId ? styles.ownerCard : ""
                  }`}
                >
                  {editingCard && editingCard.cardId === card.id ? (
                    <div
                      className={`${styles.newCardInput} ${styles.emojiPickerContainer}`}
                    >
                      <textarea
                        className="editingCardTextarea"
                        value={editCardText}
                        onChange={(e) => setEditCardText(e.target.value)}
                        onInput={(e) => autoGrow(e.target)}
                      />
                      <button
                        onClick={(event) =>
                          handleToggleEmojiPicker(event, true)
                        }
                        className={styles.emojiPickerToggle}
                      >
                        😊
                      </button>
                      {showEditingEmojiPicker && (
                        <div className={styles.emojiPickerPopup}>
                          <Picker
                            onEmojiClick={(emojiObject, event) =>
                              onEmojiClick(emojiObject, event, true)
                            }
                          />
                        </div>
                      )}
                      <div className={styles.buttonContainer}>
                        <button
                          className={styles.newCardInputButton}
                          onClick={() => handleSaveEditCard(column.id, card.id)}
                        >
                          Save
                        </button>
                        <button
                          className={styles.newCardInputButton}
                          onClick={handleCancelEditCard}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={styles.cardContent}>
                        <p className={styles.cardText}>{card.text}</p>
                        {currentUser && card.ownerId === currentUser.uid && (
                          <div
                            className={styles.cardMenu}
                            onClick={(e) => handleToggleCardMenu(card.id, e)}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} />
                            <div
                              className={
                                activeCardMenu === card.id
                                  ? styles.cardActions
                                  : styles.hidden
                              }
                            >
                              <button
                                onClick={() => handleEditCard(column.id, card)}
                              >
                                Edit card
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteCard(column.id, card.id)
                                }
                              >
                                Delete card
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={styles.cardFooter}>
                        <span className={styles.cardAuthor}>{card.author}</span>
                        <VoteButton
                          boardId={boardId}
                          columnId={column.id}
                          cardId={card.id}
                          votedBy={card.votedBy}
                          votes={card.votes}
                          currentUser={currentUser}
                        />
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BoardPage;
