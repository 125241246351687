// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDG19ScWekpMYiyD7rdin5STy_XQYPL_F0",
  authDomain: "retropaw.com",
  databaseURL: "https://amoretro-5b8e4-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "amoretro-5b8e4",
  storageBucket: "amoretro-5b8e4.appspot.com",
  messagingSenderId: "62924167099",
  appId: "1:62924167099:web:202c669e1be1853cc8569e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);
export const auth = getAuth(app);