import { ref, get, update, push, remove, runTransaction, set, onValue, off } from 'firebase/database';
import { database } from '../firebase-config';
import { getAuth } from "firebase/auth";

export const listenBoardChanges = (boardId, setBoard) => {
  const boardRef = ref(database, `boards/${boardId}`);
  
  const unsubscribe = onValue(boardRef, (snapshot) => {
      if (snapshot.exists()) {
          const board = snapshot.val();
          // Трансформація колонок та карток з об'єкта у масив
          const columns = board.columns
            ? Object.keys(board.columns).map(columnKey => {
                const cards = board.columns[columnKey].cards
                  ? Object.keys(board.columns[columnKey].cards).map(cardKey => {
                      return {
                        id: cardKey,
                        ...board.columns[columnKey].cards[cardKey]
                      };
                    })
                  : [];
                return {
                  id: columnKey,
                  name: board.columns[columnKey].name,
                  cards
                };
              })
            : [];
          const transformedBoardData = {
            id: boardId,
            ...board,
            columns
          };
          setBoard(transformedBoardData);
      } else {
          setBoard(null);
      }
  });

  // Повертаємо функцію для скасування підписки
  return unsubscribe;
};

  export const getBoards = async () => {
    try {
      const boardsRef = ref(database, 'boards');
      const snapshot = await get(boardsRef);
      if (snapshot.exists()) {
        const boards = snapshot.val();
        // Фільтруємо тільки неархівовані дошки
        return Object.keys(boards).filter(key => !boards[key].isArchived).map(key => ({
          id: key,
          ...boards[key]
        }));
      } else {
        // Якщо немає даних, повертаємо пустий масив
        return [];
      }
    } catch (error) {
      console.error("Error fetching boards: ", error);
      throw error;
    }
  };

export const addCardToColumn = async (boardId, columnId, cardData) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    try {
      const newCardRef = push(ref(database, `boards/${boardId}/columns/${columnId}/cards`));
      const newCardData = {
        ...cardData,
        ownerId: user.uid,
        author: user.displayName || 'Anonymous' // Використання імені користувача як автора картки
      };

      await set(newCardRef, newCardData);

      return newCardRef.key;
    } catch (error) {
      console.error("Error adding new card: ", error);
      throw error;
    }
  } else {
    throw new Error("User not authenticated");
  }
};

  export const editCard = async (boardId, columnId, cardId, cardData) => {
    try {
      // Шлях до конкретної картки в базі даних
      const cardRef = ref(database, `boards/${boardId}/columns/${columnId}/cards/${cardId}`);
      
      // Оновлюємо картку з новими даними
      await update(cardRef, cardData);
      
      // Операція успішна, можемо повертати true або ID картки
      return true;
    } catch (error) {
      console.error("Error updating card: ", error);
      throw error;
    }
  };

  export const deleteCard = async (boardId, columnId, cardId) => {
    try {
      const cardRef = ref(database, `boards/${boardId}/columns/${columnId}/cards/${cardId}`);
      await remove(cardRef);
    } catch (error) {
      console.error("Error deleting card: ", error);
      throw error;
    }
  };

  export const updateCardVotes = async (boardId, columnId, cardId, userId, hasVoted) => {
    const cardRef = ref(database, `boards/${boardId}/columns/${columnId}/cards/${cardId}`);
    try {
      await runTransaction(cardRef, (card) => {
        if (card) {
          if (!card.votedBy) {
            card.votedBy = [];
          }
          const voteIndex = card.votedBy.indexOf(userId);
          if (voteIndex === -1) {
            // Користувач не голосував, додати голос
            card.votes = (card.votes || 0) + 1;
            card.votedBy.push(userId);
          } else {
            // Користувач вже голосував, відняти голос
            card.votes = card.votes - 1;
            card.votedBy.splice(voteIndex, 1);
          }
        }
        return card;
      });
    } catch (error) {
      console.error("Error updating card votes: ", error);
      throw error;
    }
  };

  export const addNewBoard = async (userId, boardName) => {
    const boardsRef = ref(database, 'boards');
    const newBoardRef = push(boardsRef);
    const newBoardData = {
      name: boardName,
      date: new Date().toISOString(), // Записуємо поточну дату в форматі ISO
      ownerId: userId, // Власник борди
      columns: {
        'column1': { name: 'Went Well', cards: {} },
        'column2': { name: 'To Improve', cards: {} },
        'column3': { name: 'Action Items', cards: {} }
      }
    };
  
    try {
      await set(newBoardRef, newBoardData);
      return newBoardRef.key;
    } catch (error) {
      console.error("Error creating new board: ", error);
      throw error;
    }
  };

  export const archiveBoard = async (boardId) => {
    const boardRef = ref(database, `boards/${boardId}`);
    await update(boardRef, { isArchived: true });
  };

  export const renameBoard = async (boardId, newName) => {
    const boardRef = ref(database, `boards/${boardId}`);
    try {
      await update(boardRef, { name: newName });
    } catch (error) {
      console.error("Error renaming board: ", error);
      throw error;
    }
  };

  export const startTimerInFirebase = (seconds) => {
    const startTime = Date.now();
    const timerData = { startTime, duration: seconds };
    set(ref(database, 'timers/activeTimer'), timerData);
};

export const listenForTimerChanges = (callback) => {
  const timerRef = ref(database, 'timers/activeTimer');
  onValue(timerRef, (snapshot) => {
      const timerData = snapshot.val();
      callback(timerData);
  });

  // Повертаємо функцію для відписки
  return () => {
      off(timerRef); // Вимикаємо слухача
  };
};

export const cancelTimerInFirebase = () => {
    remove(ref(database, 'timers/activeTimer'));
};

// Функція для створення або оновлення таймера
export const setTimer = (boardId, seconds) => {
  const startTime = Date.now();
  set(ref(database, `boards/${boardId}/timer`), {
    startTime,
    seconds,
  });
};

// Функція для слухання змін таймера
export const onTimerChange = (boardId, callback) => {
  const timerRef = ref(database, `boards/${boardId}/timer`);
  onValue(timerRef, (snapshot) => {
    callback(snapshot.val());
  });

  // Повертаємо функцію для зупинки слухання
  return () => off(timerRef);
};

// Функція для видалення таймера
export const removeTimer = (boardId) => {
  remove(ref(database, `boards/${boardId}/timer`));
};