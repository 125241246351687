import React, { memo } from 'react';
import { getAuth } from "firebase/auth";
import { updateCardVotes } from "../services/firebaseService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp as farThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp as fasThumbsUp } from "@fortawesome/free-solid-svg-icons";
import styles from './VoteButton.module.css'; // Припускаючи, що ви створили окремий файл стилів

const VoteButton = memo(({ boardId, columnId, cardId, votedBy, votes, currentUser }) => {
  const handleVote = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        await updateCardVotes(boardId, columnId, cardId, user.uid, !(votedBy && votedBy.includes(user.uid)));
      } catch (err) {
        console.error(err); // Змінено з setError на console.error для прикладу
      }
    } else {
      console.log("User is not logged in");
    }
  };

  return (
    <div className={styles.voteContainer}>
      <button onClick={handleVote} className={styles.voteButton}>
        <FontAwesomeIcon
          icon={votedBy && votedBy.includes(currentUser?.uid) ? fasThumbsUp : farThumbsUp}
          className={votedBy && votedBy.includes(currentUser?.uid) ? "voted" : ""}
        />
        <span> {votes}</span>
      </button>
    </div>
  );
});

export default VoteButton;