import React, { useState } from "react";
import styles from "./CardDraw.module.css";

const CardDraw = ({ users }) => {
  const [cards, setCards] = useState(shuffleArray(users));
  const [revealedCards, setRevealedCards] = useState([]);

  function shuffleArray(array) {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }

  const handleCardClick = (userId) => {
    if (!revealedCards.includes(userId)) {
      setRevealedCards([...revealedCards, userId]);
    }
  };

  const handleShuffle = () => {
    setCards(shuffleArray(users));
    setRevealedCards([]);
  };

  return (
    <div className={styles.cardDraw}>
      <div className={styles.cardsWrapper}>
        <div className={styles.cardsContainer}>
          {cards.map((user) => (
            <div
              key={user.id}
              className={`${styles.card} ${
                revealedCards.includes(user.id) ? styles.revealed : ""
              }`}
              onClick={() => handleCardClick(user.id)}
            >
              <div className={styles.cardBack}></div>
              <div className={styles.cardFront}>
                <img src={user.photoURL} alt={user.displayName} />
                <p>{user.displayName}</p>
              </div>
            </div>
          ))}
        </div>
        <img
           src={`${process.env.PUBLIC_URL}/shuffle.png`}
          alt="Shuffle"
          className={styles.shuffleIcon}
          onClick={handleShuffle}
        />
      </div>
    </div>
  );
};

export default CardDraw;
