import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import styles from './Navbar.module.css';

const Navbar = () => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();    
    // eslint-disable-next-line
  }, []);

  return (
    <header className={styles.navbar}>
    <div className={styles.navbarContent}>
      <img src="/icon.svg" className={styles.icon} alt="Icon" />
      <Link to="/" className={styles.navbarTitle}>RetroPaw</Link>
      </div>
      <Link to='/dashboard' className={styles.navbarCenter}>Dashboard</Link>
      {user ? (
        <div className={styles.navbarUser}>
          <img src={user.photoURL} alt="User" className={styles.userPhoto} referrerpolicy="no-referrer" />
          <Link to="/user" className={styles.navbarUser}>{user.displayName || 'Your Profile'}</Link>
        </div>
      ) : (
        <Link to="/login" className={styles.navbarUser}>Login</Link>
      )}
    </header>
  );
};

export default Navbar;